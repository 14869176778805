/* You can add global styles to this file, and also import other style files */
@import './colors';
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';
@import 'styles/deep-replacement';

/* Das Styling der Scrollbar für Webkit-Browser */
::-webkit-scrollbar {
  width: 10px; /* Breite der Scrollbar */
  height: 10px; /* Höhe der Scrollbar */
}

/* Der Griff der Scrollbar */
::-webkit-scrollbar-thumb {
  background: var(--click); /* Farbe des Griffs */
  border-radius: 6px; /* abgerundete Ecken für den Griff */
}

/* Das Track (der Weg) der Scrollbar */
::-webkit-scrollbar-track {
  background: var(--secondary-hover); /* Farbe des Wegs */
  border-radius: 6px; /* abgerundete Ecken für den Weg */
}

/* Der Hover-Effekt für den Griff */
::-webkit-scrollbar-thumb:hover {
  background: var(--hover); /* Farbe des Griffs beim Hover */
}

p {
  font-size: clamp(14px, 2vmin, 18px);
}

.p-inputtext:enabled:hover {
  border-color: var(--secondary-hover);
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--hover);
  border-color: var(--click);
}

body {
  font-display: swap;
  color: var(--text-color-primary);
  font-size: 16px;
  font-family: var(--primary-font);
  font-weight: 400;
  background-color: var(--body-background-color);
}

pre {
  color: var(--primary);
  font-size: 16px;
  font-family: var(--primary-font);
  font-weight: 400;
}

h1 {
  color: var(--primary);
  font-size: clamp(24px, 5vmin, 40px);
  font-family: var(--primary-font);
  font-weight: 600;
  word-wrap: break-word;
}

h2 {
  color: var(--primary);
  font-size: clamp(20px, 4vmin, 36px);
  font-family: var(--primary-font);
  font-weight: 600;
  word-wrap: break-word;
}

h3 {
  color: var(--primary);
  font-size: clamp(18px, 3.5vmin, 32px);
  font-family: var(--primary-font);
  font-weight: 600;
  word-wrap: break-word;
}

h4 {
  color: var(--primary);
  font-size: clamp(16px, 3vmin, 28px);
  font-family: var(--primary-font);
  font-weight: 600;
  word-wrap: break-word;
}

h5 {
  color: var(--primary);
  font-size: clamp(14px, 2.5vmin, 24px);
  font-family: var(--primary-font);
  font-weight: 600;
  word-wrap: break-word;
}

/* Some PrimeNG-Module needs it on this layer */
.upload-files {
  margin-top: 20px;
  border: 1px solid #d1d5db;
  border-radius: 50px;
  border-top-right-radius: 0;
  color: black;
  background-color: var(--background-element);
}

.p-button {
  box-shadow: none;
  color: var(--corporate-color-one);
  background: var(--primary);
  border: none;
}

.secondary-button {
  color: var(--primary);
  background: var(--corporate-color-one);
}

.secondary-button .p-button {
  color: var(--primary);
  background: var(--corporate-color-one);
}

.secondary-button:hover {
  background: var(--secondary-hover);
}

.secondary-button:enabled:hover {
  color: var(--primary) !important;
  background: var(--secondary-hover) !important;
}

.secondary-button .p-button:hover {
  color: var(--primary) !important;
  background: var(--secondary-hover) !important;
}

.no-border {
  border: none;
}

.p-button:enabled:hover {
  color: var(--corporate-color-one);
  background: var(--click);

  // border: none;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: var(--secondary-click);
  color: var(--click);
  border-color: var(--secondary-hover);
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--corporate-border-color);
  background: var(--corporate-border-color);
}

:focus-visible {
  outline: var(--click) auto 1px; /* Zum Beispiel in Rot und 2px breit */
}

.p-dropdown:not(.p-disabled):hover {
  border-color: var(--hover);
}

-p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-hover);
  border-color: var(--hover);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--primary);
  background: var(--secondary-hover);
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  background: var(--click);
  border-color: var(--hover);
  box-shadow: 0 0 0 0.2rem var(--secondary-click);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled, .p-highlight):hover {
  border-color: var(--hover);
}

.content-left {
  display: flex;
  column-gap: 1rem;
  flex-direction: row;
  justify-content: flex-start;
}

.content-center {
  display: flex;
  column-gap: 1rem;
  flex-direction: row;
  justify-content: center;
}

.content-right {
  display: flex;
  column-gap: 1rem;
  flex-direction: row;
  justify-content: flex-end;
}

.p-splitbutton.p-component {
  height: 30px;
}

.glammer {
  font-size: 36px;
  font-weight: bold;
  background: linear-gradient(
    74deg,
    gray,
    var(--click),
    var(--primary),
    var(--accent),
    black
  ); /* Defines the gradient colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.info-icon-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-left: 5px;
}

.info-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: white;
  color: var(--accent);
  border: 2px solid var(--accent);
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tooltip {
  display: none;
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--corporate-color-one);
  color: var(--text-color-primary);
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  white-space: normal;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: max-content;
  max-width: 1000px;
  word-wrap: break-word;
}

.info-icon-wrapper:hover .tooltip {
  display: block;
}