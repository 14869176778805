app-profile .invite-card {
  white-space: normal;
  margin: auto;
}

app-homepage .contact-form-button {
  background: var(--corporate-border-color);
  color: var(--corporate-color-one);
  border-radius: 25px 0 25px 25px;
  border-color: var(--corporate-border-color);
}

app-homepage .contact-form-button:hover {
  background: var(--hover);
  border-color: var(--hover);
  color: var(--corporate-color-one);
}

app-homepage .contact-dialog {
  max-height: 95%;
}

app-display-file-metadata .p-datatable {
  margin-top: 5px;
  margin-bottom: 30px;
}

app-display-file-metadata .delete-button {
  padding: 12px 0 !important;
  background-color: inherit !important;
}

app-display-file-metadata .delete-button:hover {
  padding: 12px 0 !important;
  background-color: var(--secondary-hover);
}

app-display-file-metadata button.p-button[type='button'] {
  border: none;
  background-color: inherit !important;
}

app-display-file-metadata .p-button.p-button-icon-only {
  width: 2rem;
  height: 2rem;
}

app-display-file-metadata .check-button .p-button-icon {
  color: var(--primary);
}

app-display-file-metadata .check-button .p-button-icon:hover {
  color: green;
  font-size: 28px;
  font-weight: bold;
}

app-display-file-metadata .cancel-button .p-button-icon {
  color: var(--primary);
}

app-display-file-metadata .cancel-button .p-button-icon:hover {
  color: red;
  font-size: 28px;
  font-weight: bold;
}

app-display-file-metadata p-celleditor textarea {
  border-radius: 5px;
  border-color: var(--primary) !important;
  height: fit-content;
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  color: var(--primary);
  padding: 8px;
}

app-display-file-metadata p-celleditor input {
  border-radius: 5px;
  border-color: var(--primary) !important;
  height: fit-content;
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  color: var(--primary);
  padding: 8px;
}

app-display-file-metadata tr.selected-row {
  background-color: rgb(240 242 248);
}

app-display-file-metadata button.p-button[type='button'].p-confirm-dialog-accept {
  background-color: var(--primary) !important;
}

app-display-file-metadata button.p-button[type='button'].p-confirm-dialog-reject {
  background-color: var(--primary) !important;
}

app-display-website-metadata .p-datatable {
  margin-top: 5px;
  margin-bottom: 30px;
}

app-display-website-metadata button.p-button[type='button'] {
  border: none;
  background-color: inherit !important;
}

app-display-website-metadata .p-button.p-button-icon-only {
  width: 2rem;
  height: 2rem;
}

app-display-website-metadata p-celleditor textarea {
  border-radius: 5px;
  border-color: var(--primary) !important;
  height: fit-content;
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  color: var(--primary);
  padding: 8px;
}

app-display-website-metadata p-celleditor input {
  border-radius: 5px;
  border-color: var(--primary) !important;
  height: fit-content;
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  color: var(--primary);
  padding: 8px;
}

app-display-website-metadata tr.selected-row {
  background-color: rgb(240 242 248);
}

app-display-website-metadata .check-button .p-button-icon {
  color: var(--primary);
}

app-display-website-metadata .check-button .p-button-icon:hover {
  color: green;
  font-size: 28px;
  font-weight: bold;
}

app-display-website-metadata .cancel-button .p-button-icon {
  color: var(--primary);
}

app-display-website-metadata .cancel-button .p-button-icon:hover {
  color: red;
  font-size: 28px;
  font-weight: bold;
}

app-display-website-metadata button.p-button[type='button'].p-confirm-dialog-accept {
  background-color: var(--primary) !important;
}

app-display-website-metadata button.p-button[type='button'].p-confirm-dialog-reject {
  background-color: var(--primary) !important;
}

app-sidebar .p-sidebar {
  height: auto;
  margin-top: 65px;
  width: auto;
}

app-sidebar .p-sidebar-content {
  padding: 0 20 !important;
  overflow: hidden;
}

app-sidebar .p-sidebar-header {
  padding: 0 !important;
}

app-sidebar .p-sidebar-footer {
  padding: 0 !important;
}

app-sidebar .sidebar-menu-button {
  border: 0 !important;
}

app-sidebar .sidebar-menu-button:focus {
  box-shadow: none !important;
}

// ask Gerrit how to solve this better (!important)
app-sidebar .sidebar-menu-highlighted {
  background: var(--click) !important;
  color: var(--corporate-color-one) !important;
}

app-header {
  .p-splitbutton a:hover {
    background: var(--hover);

    span {
      color: var(--consileon-white) !important;
    }
  }

  .p-splitbutton button:first-child:hover {
    background: var(--corporate-color-one) !important;
    color: var(--primary) !important;
  }

  .p-splitbutton button {
    cursor: default;
    background: var(--corporate-color-one);
    color: var(--primary);
  }
}

app-header .p-button {
  border: none;
  color: var(--primary);
  background: var(--corporate-color-one);
  height: 30px;
}

app-header .p-button:enabled:hover {
  border: none;
}

app-projects-overview .custom-spinner .p-progress-spinner-circle {
  animation: custom-keyframes 8s ease-in-out infinite;
}

@keyframes custom-keyframes {
  100%,
  0% {
    stroke: var(--primary);
  }
}

app-sign-in .back-to-homepage-button {
  background: var(--corporate-border-color);
  color: var(--corporate-color-one);
  border-radius: 25px 0 25px 25px;
  border-color: var(--corporate-border-color);
}
