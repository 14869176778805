:root {
  --primary: #1a233b;
  --hover: #5e7388;
  --click: #4c6176;
  --accent: #cc0133;
  --secondary-hover: #eff2f7;
  --secondary-click: #e2e9f3;
  --text-color-primary: #1a233b;
  --text-color-secondary: #1a233b;
  --background-element: #eff2f7;
  --body-background-color: #fafafc;
  --corporate-color-one: #fff;
  --corporate-border-color: #c03;
  --corporate-color-three: #1a233b;
}
